<template>
  <div id="ppt">
    <div class="box">
      <div class="main">
        <span>{{ name }}</span>
        <div class="pic">
          <img src="../../img/上课/音线.jpg" alt="" />
          <img src="../../img/上课/音线.jpg" alt="" />
        </div>
        <div class="roll">
          <div class="img">
            <img src="../../img/上课/黑胶.png" alt="" />
          </div>
          <div class="btnimg" @click="control">
            <img
              v-if="btn == 0"
              class="stop"
              src="../../img/上课/暂停.png"
              alt=""
            />
            <img v-else class="play" src="../../img/上课/播放.png" alt="" />
          </div>
        </div>
        <div class="music">
          <audio
            id="audio"
            controls
            loop
            @click="control"
            controlsList="nodownload"
            :src="music"
          ></audio>
        </div>
        <!-- <img class="img1" :src="imgurl" alt="" /> -->
      </div>
    </div>
  </div>
</template>
<script>
import * as util from "@/unit/network";
import $ from "jquery";
export default {
  data() {
    return {
      name: "",
      music: "",
      imgurl: "",
      btn: 1,
      aaa: document.getElementById("audio"),
    };
  },
  mounted() {
    this.getinfo();
    console.log(this.aaa);
    // $(".pic img").css({
    //   webkitAnimationPlayState: "paused",
    // });
    // $(".roll .img img").css({
    //   webkitAnimationPlayState: "paused",
    // });
    var audio = document.getElementById("audio");

    // console.log(audio.duration);
    if (audio.paused) {
      $(".pic img").css({
        webkitAnimationPlayState: "paused",
      });
      $(".roll .img img").css({
        webkitAnimationPlayState: "paused",
      });
    } else {
      $(".pic img").css({
        webkitAnimationPlayState: "running",
      });
      $(".roll .img img").css({
        webkitAnimationPlayState: "running",
      });
    }
  },
  methods: {
    getinfo() {
      util.post("/catalogFileInfo", { id: this.$route.query.dataid }, (res) => {
        this.imgurl = res.data.cover;
        this.name = res.data.file_name;
        this.music = res.data.file_path;
      });
    },
    control() {
      var audio = document.getElementById("audio");
      console.log(audio.duration);
      console.log(this.aaa);
      if (audio !== null) {
        if (audio.paused) {
          audio.play(); // 播放
          this.btn = 0;
          $(".pic img").css({
            webkitAnimationPlayState: "running",
          });
          $(".roll .img img").css({
            webkitAnimationPlayState: "running",
          });
        } else {
          audio.pause(); // 暂停
          this.btn = 1;

          $(".pic img").css({
            webkitAnimationPlayState: "paused",
          });
          $(".roll .img img").css({
            webkitAnimationPlayState: "paused",
          });
        }
      }
    },
  },
  watch: {
    aaa(o, n) {
      console.log(n, o);
      // if (n == 1) $("#audio").paused;
      console.log(this.aaa.paused, this.btn);
    },
  },
};
</script>
<style lang="less" scoped>
#ppt {
  height: 100%;
  .box {
    height: 100%;
    // min-height: 1020px;
    position: relative;
  }
  .main {
    width: 91%;
    height: 744px;
    // background: url();
    position: absolute;
    // top: 50%;
    left: 50%;
    transform: translate(-50%, 9%);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: space-between;
    overflow: hidden;
    background: #2d3b5c;
    span {
      display: inline-block;
      width: 100%;
      height: 130px;
      background-color: rgba(0, 0, 0, 0.8);
      text-align: left;
      color: #fff;
      background: linear-gradient(to bottom, rgba(0, 0, 0, 0.8), transparent);
      padding: 31px 0 0 61px;
      font-size: 16px;
      font-weight: bold;
    }
    .pic {
      width: 1164px;
      height: 257px;
      background-color: #1c7cff;
      border-radius: 20px;
      display: flex;
      overflow: hidden;
      position: relative;
      img {
        height: 100%;
        animation: myimg 11s linear infinite;
      }
      @keyframes myimg {
        0% {
          transform: translateX(0);
        }
        100% {
          transform: translateX(-1211.56px);
        }
      }
    }
    .roll {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -55%);
      > .img {
        > img {
          width: 410px;
          animation: rollimg 6s linear infinite;
        }
      }
      .btnimg {
        cursor: pointer;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }

      @keyframes rollimg {
        0% {
          transform: rotate(0);
        }
        100% {
          transform: rotate(360deg);
        }
      }
      .pause {
        animation-play-state: paused;
      }
    }
    audio {
      z-index: 9;
      width: 100%;
      border-radius: 0 !important;
    }
    .music {
      width: 100%;
      height: 130px;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      background: linear-gradient(to top, rgba(0, 0, 0, 0.8), transparent);
    }
    .img1 {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;

      z-index: -1;
    }
  }
}
</style>